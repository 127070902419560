import { http } from "@/utils/http";
import axios from "axios";
import qs from "querystring";

export const getRouters = async () => {
  const res = await http.get("/getRouters");
  return res.data?.data;
};

export const getSystemMenuList = async () => {
  const res = await http.get("/system/menu/list");
  return res.data?.data;
};

export const getSystemMenu = async (params) => {
  const res = await http.get(`/system/menu/${params.id}`);
  return res.data?.data;
};

export const postSystemMenu = async (data) => {
  await http.post(`/system/menu`, data);
};

export const putSystemMenu = async (data) => {
  await http.put(`/system/menu`, data);
};

export const deleteSystemMenu = async (params) => {
  await http.delete(`/system/menu/${params.id}`);
};

export const getSystemDeptListTree = async (params) => {
  const res = await http.get(`/system/dept/list/tree`, {
    params,
  });
  return res.data?.data;
};

export const getSystemDept = async (params) => {
  const res = await http.get(`/system/dept/${params?.id}`);
  return res.data?.data;
};

export const getSystemRoleList = async () => {
  const res = await http.get(`system/role/list`);
  return res.data?.data;
};

export const deleteSystemRole = async (params) => {
  await http.delete(`system/role/${params.id}`);
};

export const postSystemRole = async (data) => {
  await http.post(`system/role`, data);
};

export const putSystemRole = async (data) => {
  await http.put(`system/role`, data);
};

export const getSystemRole = async (params) => {
  const res = await http.get(`system/role/${params.id}`);
  return res.data?.data;
};

export const getSystemUserPage = async (params) => {
  const res = await http.get(`system/user/page`, {
    params,
  });
  return res.data?.data;
};

export const deleteSystemUser = async (params) => {
  await http.delete(`system/user/${params.id}`);
};

export const getSystemUser = async (params) => {
  const res = await http.get(`system/user/${params.id}`);
  return res.data?.data;
};

export const postSystemUser = async (data) => {
  const res = await http.post(`system/user`, data);
  return res.data?.data;
};

export const putSystemUser = async (data) => {
  const res = await http.put(`system/user`, data);
  return res.data?.data;
};

export const getSystemDeptTreeselect = async () => {
  const res = await http.get(`system/dept/treeselect`);
  return res.data?.data;
};

export const getSystemPostList = async (params) => {
  const res = await http.get(`system/post/list`, { params });
  return res.data?.data;
};

export const deleteSystemPost = async (params) => {
  await http.delete(`system/post/${params.id}`);
};

export const postSystemPost = async (data) => {
  await http.post(`system/post`, data);
};

export const putSystemPost = async (data) => {
  await http.put(`system/post`, data);
};

export const getSystemPost = async (params) => {
  const res = await http.get(`system/post/${params.id}`);
  return res.data?.data;
};

export const getSystemMenuTreeselect = async () => {
  const res = await http.get(`system/menu/treeselect`);
  return res.data?.data;
};

export const postSystemDept = async (data) => {
  const res = await http.post(`system/dept`, data);
  return res.data?.data;
};

export const putSystemDept = async (data) => {
  const res = await http.put(`system/dept`, data);
  return res.data?.data;
};

export const getSystemDictTypePage = async (params) => {
  const res = await http.get(`/system/dict/type/page`, { params });
  return res.data?.data;
};

export const getSystemDictType = async (params) => {
  const res = await http.get(`/system/dict/type/${params.id}`);
  return res.data?.data;
};

export const deleteSystemDictType = async (params) => {
  return http.delete(`/system/dict/type/${params.id}`);
};

export const putSystemDictType = async (data) => {
  return http.put(`/system/dict/type`, data);
};

export const postSystemDictType = async (data) => {
  return http.post(`/system/dict/type`, data);
};

export const getSystemDictDataPage = async (params) => {
  const res = await http.get<{
    data: {
      records: {
        dictType: string;
        dictValue: string;
        dictLabel: string;
      }[];
      total: number;
    };
  }>(`/system/dict/data/page`, {
    params,
  });
  return res.data?.data;
};

export const getSystemDictData = async (params) => {
  const res = await http.get(`/system/dict/data/${params.id}`);
  return res.data?.data;
};

export const putSystemDictData = async (data) => {
  return http.put(`system/dict/data`, data);
};

export const postSystemDictData = async (data) => {
  return http.post(`system/dict/data`, data);
};

export const deleteSystemDictData = async (data) => {
  return http.delete(`system/dict/data/${data.id}`);
};

export const postFsLogin = async (data) => {
  const res = await http.post("/login/fsqr", data);
  return res.data?.data;
};

export const putSystemUserResetPwd = async (data) => {
  return http.put(`system/user/resetPwd`, data);
};

export const deleteSystemDeptData = async (params) => {
  return http.delete(`system/dept/${params.id}`);
};

export const putSystemUserProfileUpdatePwd = (data) => {
  return http.put("/system/user/profile/updatePwd", data);
};

export const getSystemUserProfile = async () => {
  const res = await http.get("/system/user/profile");
  return res.data?.data;
};
export const getAuthCode = async () => {
  const res = await http.get("", {
    baseURL: `${import.meta.env.VITE_DOWNLOAD_API}/nas/webapi/auth.cgi`,
    headers: {
      fileservice_key: "20230707",
    },
  });
  return res.data?.data;
};
export const downloadFileAndName = async (params: string) => {
  const url = `${import.meta.env.VITE_DOWNLOAD_API}/webapi/entry.cgi?`;
  const searchParams = new URLSearchParams();
  searchParams.append("api", "SYNO.FileStation.Download");
  searchParams.append("mode", "open");
  searchParams.append("version", "2");
  searchParams.append("method", "download");
  searchParams.append("path", params);
  const newUrl = url + searchParams.toString();
  const res = await http.get("", {
    baseURL: newUrl,
    responseType: "blob",
    headers: {
      fileservice_key: "20230707",
    },
  });
  const disposition = res.headers["content-disposition"];
  let filename = "unknow";

  if (disposition) {
    filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];

    if (filename.startsWith("utf-8''")) {
      filename = decodeURIComponent(filename.replace("utf-8''", ""));
    } else if (filename.startsWith("UTF-8''")) {
      filename = decodeURIComponent(filename.replace("UTF-8''", ""));
    } else {
      filename = filename.replace(/['"]/g, "");
    }
  } else {
    filename = params.split("fileKey=")[1];
  }

  return [res.data, filename];
};
export const uploadFile = (file: File | Blob) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("api", "SYNO.FileStation.Upload");
  formData.append("mode", "open");
  formData.append("version", "2");
  formData.append("method", "upload");
  formData.append("path", "/home/mltest");
  formData.append("create_parents", "true");
  return axios.post("", formData, {
    baseURL: `${import.meta.env.VITE_DOWNLOAD_API}/webapi/entry.cgi`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getMainRent = async () => {
  const res = await http.get(`/system/user/tenant`);
  return res.data?.data;
};
export const changeRentRequest = async (data: { tenant: string }) => {
  const res = await http.post(`/system/user/updateTenant`, data);
  return res.data?.data;
};

export const getConfigRealInfo = async () => {
  const res = await http.get(`/system/config/configKey/realInfo`);
  return res.data?.data;
};
export const getSystemDictDataType = async (params) => {
  const res = await http.get(`/system/dict/data/type/${params.id}`);
  return res.data?.data;
};

export const getMyTaskList = async (params: any) => {
  const res = await http.get(`/pc/process/myTask`, {
    params,
  });
  return res.data?.data;
};
export const getMyTaskListNew = async (params: any) => {
  const res = await http.get(`/pc/process/relatedTasks`, {
    params,
  });
  return res.data?.data;
};
export const getOaProcessList = async () => {
  const res = await http.get(`/pc/process/oa/process`);
  return res.data?.data;
};
