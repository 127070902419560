import { defineStore } from "pinia";
import { login, getInfo, getPermCode } from "@/api/user";
import { Notification } from "@arco-design/web-vue";
import { Message } from "@arco-design/web-vue";
import { postFsLogin } from "@/api/system";
import { reactive, toRefs, watchEffect } from "vue";

interface UserStoreState {
  roles: string[]; // 当前用户角色
  token: string; // 当前用户令牌
  id: string; // 当前用户 ID
  username: string; // 当前用户账户名
  nickname: string; // 当前用户昵称
  permissions: string[]; // 当前用户权限
  fsUserId: string; //飞书id
  settings: { [key: string]: string }; //系统设置
  remember?: boolean;
  sfDept?: string; //部门
  sfDeptCode?: string; //部门code
}

export interface SigninForm {
  username: string;
  password: string;
  remember?: boolean;
}

export interface LarkSigninForm {
  code: string;
  redirect_uri: string;
}

const getInitState = (): UserStoreState => ({
  roles: [],
  token: "",
  id: "",
  username: "",
  nickname: "",
  fsUserId: "",
  permissions: [],
  settings: {},
  sfDept: "",
  sfDeptCode: "",
});

export const useUserStore = defineStore("user", () => {
  const sessionState = JSON.parse(sessionStorage.getItem("UserStore") || "{}");
  const localState = JSON.parse(localStorage.getItem("UserStore") || "{}");

  const state = reactive<UserStoreState>(
    Object.assign(getInitState(), localState, sessionState)
  );

  const RESET = () => {
    Object.assign(state, getInitState());
  };

  async function SIGNIN(form: SigninForm) {
    const { username, password } = form;
    const data = await login({
      username,
      password,
    });
    const { access_token: token } = data;

    state.token = token;
  }

  async function LARK_SIGNIN(params: LarkSigninForm) {
    const { code, redirect_uri } = params;
    const token = await postFsLogin({
      code,
      redirectUri: redirect_uri,
    });

    state.token = token;
  }

  const SIGNOUT = () => {
    RESET();
    Notification.clear();
    Message.clear();
  };

  async function FETCH_PROFILE() {
    const data = await getInfo();
    const {
      user: { userId: id, userName, nickName, roles, fsUserId },
      settings,
      sfDept,
      sfDeptCode,
    } = data;

    state.id = id;
    state.username = userName;
    state.nickname = nickName;
    state.fsUserId = fsUserId;
    state.sfDept = sfDept;
    state.sfDeptCode = sfDeptCode;
    state.settings = settings.reduce((obj, s) => {
      obj[s.settingsKey] = s.settingsValue;
      return obj;
    }, {});
    state.roles = roles.map((r) => r.roleKey);
  }

  async function FETCH_PERMISSIONS() {
    const data = await getPermCode();
    state.permissions = data;
  }

  function SET_TOKEN(token: string) {
    state.token = token;
  }

  watchEffect(() => {
    const token = state.token;
    const _state = JSON.stringify({
      token,
    });

    sessionStorage.setItem("UserStore", _state);
    localStorage.setItem("UserStore", _state);
  });

  return {
    ...toRefs(state),
    SIGNIN,
    LARK_SIGNIN,
    SIGNOUT,
    FETCH_PROFILE,
    FETCH_PERMISSIONS,
    SET_TOKEN,
    RESET,
  };
});
